<template>
<div id="about-id" class="container-fluid">
    
    <h2>Our Mission</h2>
    <br>
    <div class="container-fluid">
    <div class="row">
        <div class="col">
        <ol class="fs-3">
	<li><p class="text-left"><b>Promote Ethical Reporting:</b> Advocate for ethical standards in journalism and encourage media outlets to prioritize accuracy, fairness, and responsible reporting over sensationalism and gossip.</p></li>
	
	<li><p class="text-left"><b>Combat Misinformation:</b> Monitor gossip media platforms for the spread of false or misleading information and provide fact-checking resources to debunk rumors and verify claims.</p></li>
	
	<li><p class="text-left"><b>Hold Media Accountable:</b> Hold gossip media outlets accountable for the impact of their reporting on individuals' reputations, privacy, and mental well-being. This may involve raising awareness about the harmful effects of gossip journalism and advocating for accountability mechanisms within the industry.</p></li>
	
	<li><p class="text-left"><b>Empower Media Consumers:</b> Provide media literacy education and resources to help the public critically evaluate gossip media content and distinguish between reliable information and rumor or speculation.</p></li>
	
	<li><p class="text-left"><b>Promote Positive Alternatives:</b> Highlight and support media outlets that prioritize substantive reporting, constructive dialogue, and public interest journalism over sensationalism and gossip.</p></li>
	
	<li><p class="text-left"><b>Engage with Stakeholders:</b> Collaborate with journalists, media professionals, educators, policymakers, and civil society organizations to address the root causes of gossip media and develop solutions to promote ethical journalism practices and media integrity.</p></li>
	</ol> 
    </div> 
    </div> 
	
	<p class="fs-3" style="text-align: left;">Overall, our mission focused on countering gossip media would be to promote a media environment that values truth, integrity, and respect for individuals' rights and dignity, while also fostering informed public discourse and safeguarding against the harmful effects of sensationalism and rumor-mongering.</p>
        
    </div> 
</div>
</template>

<script>
export default {
  name: 'about-id',
  data() {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--style scoped>
.jumbotron {
  background-image: url(../assets/white.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-color:transparent !important;
}
</style-->
