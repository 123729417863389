<template>
<html>
<head>
   <meta charset="utf-8">
   <meta name="viewport" content="width=device-width,initial-scale=1.0">
   <title>Ethiopian Media Watchdog</title>
</head>
<body>
<div id="app" class="container-fluid">

<nav class="navbar bg-dark border-bottom border-body" data-bs-theme="dark">
  <!-- Navbar content -->
<!--nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark"-->
    <div class="nav nav-tabs bg-dark" id="nav-tab" role="tablist">
      <a class="nav-link text-white bg-dark" href="/" data-toggle="tab" role="tab" aria-selected="true">Home</a>
      <a class="nav-link text-white bg-dark" href="/cases" data-toggle="tab" role="tab" aria-selected="false">Cases</a>
      <a class="nav-link text-white bg-dark" href="/ads" data-toggle="tab" role="tab" aria-selected="false">Ads</a>
      <a class="nav-link text-white bg-dark" href="/news" data-toggle="tab" role="tab" aria-selected="false">News</a>
      <a class="nav-link text-white bg-dark" href="/about" data-toggle="tab" role="tab" aria-selected="false">About</a>
      <!--a class="nav-link text-white bg-dark" href="/contact" data-toggle="tab" role="tab" aria-selected="false">Contact</a-->
    </div>
  </nav>

  <img src="./assets/ethiowatchdog.png">

    <router-view />
  </div>
<br>
<footer>
    <div class="container">
        <p>&copy; 2024 ethiowatchdog.com. All rights reserved.</p>
    </div>
</footer>
</body>
</html>
</template>

<script>
export default {
  name: 'App'
}
</script>

<router-link to="/">Home</router-link>
<router-link to="/cases">Cases</router-link>
<router-link to="/ads">Ads</router-link>
<router-link to="/news">News</router-link>
<router-link to="/about">About</router-link>
<!--router-link to="/contact">Contact</router-link-->

<style>
body {
	font-family: Arial, sans-serif;
}
ol {
	padding: 0;
}
li {
	list-style: none;
	margin-left: 0; /* Remove default list item margin */
	text-align: left; /* Set text alignment to left */
	margin-bottom: 10px; /* Add space between list items */
}.container {
            max-width: 800px;
            margin: 20px auto;
            padding: 0 20px;
        }
        h1, h2 {
            color: #333;
        }
        p {
            color: #555;
        }
        form {
            margin-top: 20px;
        }
        label, input, textarea {
            display: block;
            margin-bottom: 10px;
        }
        input[type="submit"] {
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
        }
        input[type="submit"]:hover {
            background-color: #0056b3;
        }


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
