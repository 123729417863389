<template>
<div id="home-id" class="container-fluid">

  <!--main role="main"-->

  <!-- Main jumbotron for a primary marketing message or call to action -->
  <div class="jumbotron jumbotron-fluid">
    <div class="container-fluid">
      <h1 class="display-5">Welcome to Ethiopian Media Watchdog!</h1>

	<p  class="fs-3" style="text-align: left;">Media monopoly by Abiy Ahmed’s government has been detrimental to the Ethiopian society which is diverse, rich in history, culture, and traditions, and home to a wide range of ethnic groups, languages, religions, and customs. It has enabled dictator Abiy Ahmed to manipulate public opnion, suppres dissenting voices, and undermine democratic governance. We’re really concerned about this. We’re also concerned about some Ethiopian media outlets in the diaspora those prioritize gossip over responsible reporting.</p>

	<p class="fs-3" style="text-align: left;">Media gossip refers to the dissemination of rumors, speculation, or personal anecdotes about individuals, often celebrities or public figures, without verified facts or evidence to support the claims.</p>

        <p class="fs-3" style="text-align: left;">While some media outlets may justify gossip as a form of entertainment or light-hearted reporting, there are several ethical concerns associated with this type of journalism:</p>
        
        <div class="container-fluid">
	<div class="row">
	<div class="col">
	<ol class="fs-3">
            <li><b>Lack of Verification:</b> Gossip in the media often lacks verified facts or evidence to support the claims being made. This can lead to the spread of false information and misinformation, damaging the reputation of individuals mentioned in the gossip.</li>
	<br>
<li><b>Invasion of Privacy:</b> Gossip in the media can involve the invasion of privacy, as personal details or private moments of individuals may be exposed without their consent. This can have serious consequences for the privacy and well-being of those involved.</li>
<br>
        <li><b>Sensationalism and Exaggeration:</b> Media gossip tends to prioritize sensationalism and exaggeration over accuracy and truthfulness. Stories may be embellished or distorted to attract attention and generate controversy, regardless of the impact on the individuals involved.</li>
<br>
<li><b>Harmful Effects:</b> Gossip in the media can have harmful effects on the mental health and well-being of individuals targeted by the rumors. It can lead to feelings of distress, anxiety, and shame, and may contribute to cyberbullying or harassment.</li>
<br>
        <li><b>Distracts from Important Issues:</b> Media gossip often focuses on trivial or superficial topics, diverting attention away from more important issues and events that have significant implications for society. This can undermine the role of the media as a watchdog on power and authority.</li>
<br>
</ol>
    </div>
    </div>
    </div>
          <p class="fs-3" style="text-align: left;">While gossip has long been a part of media culture, responsible journalism requires media professionals to adhere to ethical standards and principles, including truthfulness, accuracy, fairness, and respect for privacy. By prioritizing substantive reporting and avoiding the dissemination of gossip, media outlets can uphold their credibility and serve the public interest more effectively.</p>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'home-id',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--style scoped>
.jumbotron {
  background-image: url(../assets/ethio-flag1.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
}
</style-->
