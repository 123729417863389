<template>
<div class="accordion">
<div class="accordion-id" id="accordionExample">

<div
        class="accordion-item"
        v-for="(item) in items"
        :key="item.id"
        >
        <h2 class="accordion-header" :id="`heading${item.id}`">
          <button
            class="accordion-button"
            :class="{ collapsed: !item.expanded }"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${item.id}`"
            :aria-expanded="item.expanded"
            :aria-controls="`collapse${item.id}`"
          >
            <h3>{{ item.title }}</h3>
          </button>

        </h2>
        <div
          :id="`collapse${item.id}`"
          class="accordion-collapse collapse"
          :class="{ show: item.expanded }"
          :aria-labelledby="`heading${item.id}`"
          data-bs-parent="#accordionExample"
        >
     <div class="accordion-body">

        <div class="container-fluid">

        <div v-if="item.id === 'publicmessage'">
            <a
                href="/stop-genocide-to-be-distributed.pdf"
                download="stop-genocide-to-be-distributed.pdf"
                class="download-link"
            >
            Click here for pdf download.
            </a>
            <p/>
            <p/>

            <img src="../assets/stop-genocide-to-be-distributed.png" class="img-fluid" alt="Responsive Image">	
        </div>
        <div v-else-if="item.id === 'govhealey'">
            <a
                href="/letter-to-healey.pdf"
                download="letter-to-healey.pdf"
                class="download-link"
            >
            Click here for pdf download.
            </a>
            <p/>
            <p/>
            <img src="../assets/letter-to-healey1.png" class="img-fluid" alt="Responsive Image">	
            <img src="../assets/letter-to-healey2.png" class="img-fluid" alt="Responsive Image">	
        </div>    
    <div v-else-if="item.id === 'bostonprotest'">
		<img src="../assets/boston-protest.png" class="img-fluid" alt="Responsive Image">	
    </div>
    <div v-else-if="item.id === 'gojjamattack'">
       <DynamicYouTubeEmbed videoId="zrbMySNSg8E" :startOffset="90" />
    
    </div>
    <div v-else-if="item.id === 'shoaattack'">
         <DynamicYouTubeEmbed videoId="MsSWbPLB2Js" :startOffset="0" />
    </div>
    <div v-else-if="item.id === 'hermelaenglish'">
         <DynamicYouTubeEmbed videoId="BXYma2Ws-kY" :startOffset="0" />
    </div> 
    <div v-else-if="item.id === 'hermelaamharic'">
         <DynamicYouTubeEmbed videoId="vCg1l8hB5Ec" :startOffset="0" />
    </div> 
    <div v-else>
	<img src="../assets/boston-protest.png" class="img-fluid" alt="Responsive Image">	
    </div>

          </div>
          </div>


      </div>
      </div>

</div>
</div>
</template>

<script>
import DynamicYouTubeEmbed from './DynamicYouTubeEmbed.vue';
//import GovHealey from './GovHealey.vue';

export default {
  name: 'ads-id',
  data() {
    return {
      items: [
        { id: "publicmessage", title: "The message that was distributed to pedestrians.", content: "publicmessage", expanded: false },
        { id: "govhealey", title: "A hand-delivered letter to the Massachusetts Governor’s Office.", content: "govhealey", expanded: false },
        { id: "bostonprotest", title: "Protest Against the Amhara Genocide in Ethiopia!", content: "bostonprotest", expanded: false },
        { id: "gojjamattack", title: "Abiy Ahmed's drone attack in Gojjam.", content: "gojjamattack", expanded: false },
        { id: "shoaattack", title: "Abiy Ahmed's drone attack in Shoa.", content: "shoaattack", expanded: false },
        { id: "hermelaenglish", title: "Ethiopian Youth Activist Hermela Mesfin Speaks Out - English", content: "hermelaenglish", expanded: false },
        { id: "hermelaamharic", title: "Hermela Mesfin - Amharic", content: "hermelaamharic", expanded: false },
      ],
    };
  },
  methods: {
    openSectionFromHash() {
      const hash = window.location.hash.replace("#", ""); // Get the hash without the '#'
      this.items.forEach((item) => {
        item.expanded = `collapse${item.id}` === hash; // Toggle based on hash
      });
    },
  },
  mounted() {
    // Trigger on initial load
    this.openSectionFromHash();

    // Listen for hash changes
    window.addEventListener("hashchange", this.openSectionFromHash);
  },
  beforeUnmount() {
    // Cleanup listener
    window.removeEventListener("hashchange", this.openSectionFromHash);
  },
  components: {
    DynamicYouTubeEmbed
  }
};
</script>
