<template>
    <div class="video-container">
    <iframe 
      :width="iframeWidth" 
      :height="iframeHeight" 
      :src="videoUrl" 
      frameborder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
export default {
  name: "DynamicYouTubeEmbed",
  props: {
    videoId: {
      type: String,
      required: true,
    },
    startOffset: {
      type: Number,
      default: 0, // default start time in seconds
    },
  },
  computed: {
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}?start=${this.startOffset}`;
    },
    iframeWidth() {
      return 560; // Customize the width as needed
    },
    iframeHeight() {
      return 315; // Customize the height as needed
    },
  },
};
</script>

<style scoped>
/* Add your styles here if needed */
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

